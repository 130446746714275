// import * as React from "react";

// import {
//   TransitionGroup,
//   Transition as ReactTransition,
// } from "react-transition-group";

// const timeout = 250;
// const getTransitionStyles = {
//   entering: {
//     position: `absolute`,
//     opacity: 0.5,
//   },
//   entered: {
//     transition: `opacity ${timeout}ms ease-in-out`,
//     opacity: 1,
//   },
//   exiting: {
//     transition: `opacity ${timeout}ms ease-in-out`,
//     opacity: 0.5,
//   },
// };

export default function Transition({
  children,
  location,
}) {
  return children
  // return (
  //   <TransitionGroup>
  //     <ReactTransition
  //       key={location.pathname}
  //       timeout={{
  //         enter: timeout,
  //         exit: timeout,
  //       }}
  //     >
  //       {(status) => (
  //         <div
  //           style={{
  //             // @ts-expect-error
  //             ...getTransitionStyles[status],
  //           }}
  //         >
  //           {children}
  //         </div>
  //       )}
  //     </ReactTransition>
  //   </TransitionGroup>
  // );
}
