exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-levels-tsx": () => import("./../../../src/pages/a-levels.tsx" /* webpackChunkName: "component---src-pages-a-levels-tsx" */),
  "component---src-pages-academics-overview-tsx": () => import("./../../../src/pages/academics-overview.tsx" /* webpackChunkName: "component---src-pages-academics-overview-tsx" */),
  "component---src-pages-apply-now-tsx": () => import("./../../../src/pages/apply-now.tsx" /* webpackChunkName: "component---src-pages-apply-now-tsx" */),
  "component---src-pages-curriculum-final-year-tsx": () => import("./../../../src/pages/curriculum-final-year.tsx" /* webpackChunkName: "component---src-pages-curriculum-final-year-tsx" */),
  "component---src-pages-curriculum-year-four-and-five-tsx": () => import("./../../../src/pages/curriculum-year-four-and-five.tsx" /* webpackChunkName: "component---src-pages-curriculum-year-four-and-five-tsx" */),
  "component---src-pages-curriculum-year-one-tsx": () => import("./../../../src/pages/curriculum-year-one.tsx" /* webpackChunkName: "component---src-pages-curriculum-year-one-tsx" */),
  "component---src-pages-curriculum-year-two-and-three-tsx": () => import("./../../../src/pages/curriculum-year-two-and-three.tsx" /* webpackChunkName: "component---src-pages-curriculum-year-two-and-three-tsx" */),
  "component---src-pages-daral-ulum-life-admission-information-tsx": () => import("./../../../src/pages/daral-ulum-life-admission-information.tsx" /* webpackChunkName: "component---src-pages-daral-ulum-life-admission-information-tsx" */),
  "component---src-pages-daral-ulum-life-faq-tsx": () => import("./../../../src/pages/daral-ulum-life-faq.tsx" /* webpackChunkName: "component---src-pages-daral-ulum-life-faq-tsx" */),
  "component---src-pages-daral-ulum-life-library-tsx": () => import("./../../../src/pages/daral-ulum-life-library.tsx" /* webpackChunkName: "component---src-pages-daral-ulum-life-library-tsx" */),
  "component---src-pages-daral-ulum-life-overview-tsx": () => import("./../../../src/pages/daral-ulum-life-overview.tsx" /* webpackChunkName: "component---src-pages-daral-ulum-life-overview-tsx" */),
  "component---src-pages-daral-ulum-life-student-life-tsx": () => import("./../../../src/pages/daral-ulum-life-student-life.tsx" /* webpackChunkName: "component---src-pages-daral-ulum-life-student-life-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-events-friday-night-talk-tsx": () => import("./../../../src/pages/events/friday-night-talk.tsx" /* webpackChunkName: "component---src-pages-events-friday-night-talk-tsx" */),
  "component---src-pages-events-saturday-tafsir-tsx": () => import("./../../../src/pages/events/saturday-tafsir.tsx" /* webpackChunkName: "component---src-pages-events-saturday-tafsir-tsx" */),
  "component---src-pages-grading-criteria-tsx": () => import("./../../../src/pages/grading-criteria.tsx" /* webpackChunkName: "component---src-pages-grading-criteria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leadership-tsx": () => import("./../../../src/pages/leadership.tsx" /* webpackChunkName: "component---src-pages-leadership-tsx" */),
  "component---src-pages-luminaries-tsx": () => import("./../../../src/pages/luminaries.tsx" /* webpackChunkName: "component---src-pages-luminaries-tsx" */),
  "component---src-pages-our-history-tsx": () => import("./../../../src/pages/our-history.tsx" /* webpackChunkName: "component---src-pages-our-history-tsx" */),
  "component---src-pages-our-mission-tsx": () => import("./../../../src/pages/our-mission.tsx" /* webpackChunkName: "component---src-pages-our-mission-tsx" */),
  "component---src-pages-our-pedagogy-tsx": () => import("./../../../src/pages/our-pedagogy.tsx" /* webpackChunkName: "component---src-pages-our-pedagogy-tsx" */),
  "component---src-pages-our-programs-of-study-brothers-tsx": () => import("./../../../src/pages/our-programs-of-study-brothers.tsx" /* webpackChunkName: "component---src-pages-our-programs-of-study-brothers-tsx" */),
  "component---src-pages-our-programs-of-study-sisters-tsx": () => import("./../../../src/pages/our-programs-of-study-sisters.tsx" /* webpackChunkName: "component---src-pages-our-programs-of-study-sisters-tsx" */),
  "component---src-pages-what-we-offer-tsx": () => import("./../../../src/pages/what-we-offer.tsx" /* webpackChunkName: "component---src-pages-what-we-offer-tsx" */)
}

